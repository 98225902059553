// TODO: ต้องกลับมาสร้างตัว Error ใหม่ เพราะตอนนี้มันไม่สามารถแสดงข้อมูล Error ที่ถูกต้องได้
// FIXED ERROR HANDLING

import { service, factories, models } from 'powerbi-client'
import axiosInstance from '@/libs/axiosInstance'

export default {
  async generateEmbedToken() {
    try {
      const url = 'powerbi/generate-embed-token'

      const { data } = await axiosInstance.post(url)

      const reports = data.data.reports.reduce((list, item) => ({
        ...list,
        [item.name]: item.id,
      }), {})

      return {
        embedToken: data.data.token,
        expiration: data.data.expiration,
        reports,
      }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async generateEmbedReport(reportName, reportContainer) {
    try {
      const { embedToken, reports } = await this.generateEmbedToken()

      const permissions = models.Permissions.All
      const reportId = reports[reportName]
      const embedUrl = `https://app.powerbi.com/reportEmbed?${reportId}`

      const config = {
        type: 'report',
        tokenType: models.TokenType.Embed,
        accessToken: embedToken,
        embedUrl,
        id: reportId,
        permissions,
      }

      this.powerbi = new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory)
      return this.powerbi.embed(reportContainer, config)
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
